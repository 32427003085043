/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "ClashDisplay";
  src: url("../fonts/ClashDisplay-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Satoshi", sans-serif;
  --font-weight: 400;
  --content-width: 120rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: #0b6dff;
  --clr-default-300: #9fe320;
  --clr-default-400: linear-gradient(0deg, #ffffff, #ffffff), #9fe320;
  --clr-default-500: linear-gradient(0deg, #f8fafb, #f8fafb), #9fe320;
  --clr-default-600: #d9d9d9;
  --clr-default-700: linear-gradient(0deg, #122b4f, #122b4f), #9fe320;
  --clr-default-740: #eaecf0;
  --clr-default-760: #f9fafb;
  --clr-default-770: #e9e9ee;
  --clr-default-750: #071020;
  --clr-default-760: radial-gradient(
      48.69% 48.69% at 57.92% 51.31%,
      #091926 0%,
      #060e1d 100%
    ),
    #1b2534;
  --clr-default-800: radial-gradient(
      48.69% 48.69% at 57.92% 51.31%,
      #091926 0%,
      #060e1d 100%
    ),
    #9fe320;
  --clr-default-850: radial-gradient(
    48.69% 48.69% at 57.92% 51.31%,
    #091926 0%,
    #060e1d 100%
  );
  --clr-default-860: #122b4f;
  --clr-default-870: rgba(255, 255, 255, 0.1);
  --clr-default-900: #000;
  --clr-default-950: #111111;
  --clr-primary-100: #f8fafb;
  --clr-primary-200: #c7c7c7;
  --clr-primary-300: #212b3a;
  --clr-primary-400: #111111;
  --clr-primary-450: #b8b8b8;
  --clr-primary-500: rgba(0, 0, 0, 0.1);
  --clr-primary-600: #505050;
  --clr-primary-700: #f1f1f1;
  --clr-primary-800: #8ed804;
  --clr-shadow-900: 1px 1px 5px rgba(0, 0, 0, 0.04);
  --clr-shadow-800: 2px 2px 8px rgb(7, 194, 54);
  --clr-shadow-700: 3px 3px 8px rgba(18, 17, 20, 0.705);
  --clr-hover-300: #075fe4;
  --clr-radial-400: rgba(46, 104, 255, 0.04);
}

body {
  font-size: 1.8rem;
  color: var(--clr-primary-600);
  background-color: var(--clr-primary-100);
}

body.lock {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -ms-touch-action: none;
  touch-action: none;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper-sticky {
  overflow: initial;
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  width: max-content;
  max-width: 27rem;
  font-family: "Clash Display", sans-serif;
  font-weight: 500;
  text-align: center;
}

.button--blue {
  border-radius: 0.8rem;
  font-size: 1.6rem;
  line-height: 125%;
  color: var(--clr-default-100);
  background-color: var(--clr-default-200);
  transition: background-color var(--transition);
}

.button--dark {
  border-radius: 0.8rem;
  line-height: 133%;
  color: var(--clr-primary-700);
  background-color: var(--clr-default-950);
  transition: box-shadow var(--transition);
}

.button--border-green {
  border: 0.1rem solid var(--clr-default-300);
  border-radius: 0.8rem;
  font-size: 1.6rem;
  line-height: 140%;
  color: var(--clr-default-300);
  transition: box-shadow var(--transition);
}

.stack {
  display: flex;
}

.stack.column {
  flex-direction: column;
}

.stack.column-reverse {
  flex-direction: column-reverse;
}

.stack.row {
  flex-direction: row;
}

.stack.row-reverse {
  flex-direction: row-reverse;
}

.stack.justify-flex-start {
  justify-content: flex-start;
}

.stack.justify-center {
  justify-content: center;
}

.stack.justify-flex-end {
  justify-content: flex-end;
}

.stack.justify-space-between {
  justify-content: space-between;
}

.stack.justify-space-around {
  justify-content: space-around;
}

.stack.center {
  align-items: center;
  justify-content: center;
}

.stack.align-start {
  align-items: flex-start;
}

.stack.align-center {
  align-items: center;
}

.stack.align-end {
  align-items: flex-end;
}

.stack.circle-center {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.stack.wrap {
  flex-wrap: wrap;
}

.stack.no-wrap {
  flex-wrap: nowrap;
}

.stack.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.typography.h1 {
  font-family: "ClashDisplay", sans-serif;
  font-weight: 500;
  font-size: clamp(
    3.2rem,
    1.4417582418rem + 5.4945054945vw,
    8.2rem
  );
  line-height: 100%;
}

.typography.h2 {
  font-family: "ClashDisplay", sans-serif;
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    1.4153846154rem + 3.0769230769vw,
    5.2rem
  );
  line-height: 100%;
  color: var(--clr-default-950);
}

.typography.h3 {
  font-size: 2.2rem;
}

.typography.h4 {
  font-size: 1.9rem;
}

.typography.h5 {
  font-size: 1.7rem;
}

.typography.h6 {
  font-size: 1.6rem;
}

.typography.p p {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-primary-600);
}

.typography.caption {
  font-size: 1.2rem;
}

.typography.w-400 {
  font-weight: 400;
}

.typography.w-500 {
  font-weight: 500;
}

.typography.w-600 {
  font-weight: 600;
}

.typography.success {
  color: var(--clr-status-success);
}

.typography.error {
  color: var(--clr-primary-600);
}

.typography.grey {
  color: var(--clr-default-600);
}

.typography.dark {
  color: var(--clr-default-800);
}

.typography.white {
  color: var(--clr-default-100);
}

.typography.uppercase {
  text-transform: uppercase;
}

.typography.center {
  text-align: center;
}

.typography.lh-150 {
  line-height: 150%;
}

.typography .link {
  color: var(--clr-secondary-900);
  transition: color var(--transition);
}

.typography .link.focus-visible {
  outline: none;
  color: var(--clr-secondary-400);
}

.form__input label {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.form__input label span {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--clr-default-950);
}

.form__input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 0.1rem solid var(--clr-primary-500);
  border-radius: 0.8rem;
  padding: 1.5rem;
  background-color: var(--clr-primary-100);
  transition: border-color var(--transition);
}

.form__input-wrapper input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--clr-primary-400);
  background-color: transparent;
}

.form__input-wrapper input::placeholder {
  color: var(--clr-primary-450);
}

.form__input-wrapper input.focus-visible {
  outline: none;
}

.form__input-wrapper:focus-within {
  border-color: var(--clr-default-300);
}

.form__textarea label {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.form__textarea label span {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--clr-default-950);
}

.form__textarea-wrapper {
  display: flex;
  border: 1px solid var(--clr-primary-500);
  border-radius: 0.8rem;
  height: 100%;
  transition: border-color var(--transition);
}

.form__textarea-wrapper textarea {
  overflow: auto;
  border: none;
  border-radius: 0.8rem;
  padding: 1.6rem;
  width: 100%;
  min-height: 16rem;
  max-height: 20rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 140%;
  color: var(--clr-primary-400);
  background-color: var(--clr-primary-100);
  resize: none;
}

.form__textarea-wrapper textarea::placeholder {
  color: var(--clr-default-600);
}

.form__textarea-wrapper textarea.focus-visible {
  outline: none;
}

.form__textarea-wrapper:focus-within {
  border-color: var(--clr-default-300);
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input,
.iti input[type=text],
.iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box,
.iti__country-name,
.iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box,
.iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/ui/flags/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

.iti__flag.iti__np {
  background-color: transparent;
}

.swiper {
  position: relative;
  z-index: 1;
  list-style: none;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  /* Fix of Webkit flickering */
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */

.swiper-autoheight,
.swiper-autoheight .swiper-slide {
  height: auto;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-centered > .swiper-wrapper::before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}

.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-horizontal > .swiper-wrapper::before {
  height: 100%;
  width: var(--swiper-centered-offset-after);
}

.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-vertical > .swiper-wrapper::before {
  width: 100%;
  height: var(--swiper-centered-offset-after);
}

.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}

/* ===============================
=            Choices            =
=============================== */

.choices {
  position: relative;
  overflow: hidden;
  font-size: 16px;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}

.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}

.choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.25;
}

.choices[data-type*=select-one] .choices__button:hover,
.choices[data-type*=select-one] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}

.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}

.choices[data-type*=select-one]::after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}

.choices[data-type*=select-one].is-open::after {
  border-color: transparent transparent #333 transparent;
  margin-top: -7.5px;
}

.choices[data-type*=select-one][dir=rtl]::after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}

.choices[data-type*=select-multiple] .choices__button:hover,
.choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  padding: 0.8rem 1.4rem;
  border: 0.1rem solid var(--clr-primary-300);
  border-radius: 0.8rem;
  font-size: 1.6rem;
  min-height: 5.6rem;
  overflow: hidden;
  transition: border-color var(--transition);
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: var(--clr-default-500);
}

.is-open .choices__inner {
  border-radius: 0.8rem 0.8rem 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 0.8rem 0.8rem;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding-right: 1.6rem;
  width: 100%;
}

[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: var(--clr-default-100);
  border: 0.1rem solid var(--clr-default-500);
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}

.is-active.choices__list--dropdown,
.is-active.choices__list[aria-expanded] {
  visibility: visible;
}

.is-flipped .choices__list--dropdown,
.is-flipped .choices__list[aria-expanded] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.8rem 0.8rem 0 0;
}

.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  position: relative;
  padding: 14px;
  font-size: 16px;
}

[dir=rtl] .choices__list--dropdown .choices__item,
[dir=rtl] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

.choices__input::-webkit-search-decoration,
.choices__input::-webkit-search-cancel-button,
.choices__input::-webkit-search-results-button,
.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear,
.choices__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  color: var(--clr-default-600);
}

.choices__list--dropdown .choices__placeholder {
  display: none;
}

/* =====  End of Choices  ====== */

.menu {
  position: relative;
  padding-left: 3rem;
}

.menu::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0.1rem;
  height: 1.7rem;
  background-color: var(--clr-default-870);
  transform: translateY(-50%);
}

.menu__list {
  align-items: center;
  gap: 3rem;
}

.menu__item a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--clr-primary-200);
  transition: color var(--transition);
  gap: 1.5rem;
}

.menu__item a::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-default-300);
  transition: width 0.3s;
}

.menu__item--enter {
  display: none;
}

.menu__item--btns {
  display: none;
}

.logo {
  flex-shrink: 0;
  max-width: 22rem;
}

.burger {
  display: none;
}

.header {
  z-index: 5;
  min-height: 8rem;
}

.header__logo-wrapper {
  gap: 3rem;
}

.header__enter-list {
  position: relative;
  align-items: center;
  padding-left: 3rem;
  gap: 3rem;
}

.header__enter-list::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0.1rem;
  height: 1.7rem;
  background-color: var(--clr-default-870);
  transform: translateY(-50%);
}

.header__enter-item a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--clr-primary-200);
  gap: 1rem;
}

.header__enter-item a::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-default-300);
  transition: width 0.3s;
}

.header__enter-item a svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--clr-default-860);
}

.header__enter-item.active a::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -4px;
  width: 60%;
  height: 2px;
  background-color: var(--clr-default-300);
  transition: width 0.3s;
}

.header__menu-wrapper {
  gap: 3rem;
}

.header__separator {
  display: block;
  width: 0.1rem;
  height: 1.7rem;
  background-color: var(--clr-default-870);
}

.header__buttons {
  align-items: center;
  gap: 1.5rem;
}

.header__our {
  position: relative;
  font-size: 1.5rem;
  line-height: 133%;
  color: var(--clr-primary-200);
  transition: color var(--transition);
}

.header__our::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-default-300);
  transition: width 0.3s;
}

.header__button-registration {
  border: 0.1rem solid var(--clr-default-870);
  border-radius: 0.8rem;
  min-width: 15rem;
  min-height: 4.5rem;
  font-size: 15px;
  color: var(--clr-default-100);
  transition: border var(--transition), color var(--transition);
}

.footer {
  position: relative;
  overflow: hidden;
  padding-top: 25rem;
  padding-bottom: 13rem;
  background-image: var(--clr-default-850);
}

.footer__info {
  z-index: 2;
  max-width: 69rem;
  gap: 2.5rem;
}

.footer__top {
  gap: 3rem;
}

.footer__links {
  gap: 0.8rem;
}

.footer__link a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  background-color: var(--clr-default-200);
  transition: background-color var(--transition);
}

.footer__link a svg {
  width: 1.9rem;
  height: 1.9rem;
  fill: var(--clr-default-100);
}

.footer__bottom {
  gap: 2.5rem;
}

.footer__text p {
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--clr-primary-200);
}

.footer__policy {
  gap: 3rem;
}

.footer__policy-item a {
  font-size: 1.8rem;
  line-height: 150%;
  color: var(--clr-primary-200);
  transition: color var(--transition);
}

.footer__image {
  position: absolute;
  right: 0;
  bottom: -22rem;
  z-index: 0;
  width: 89.5rem;
  height: 89.6rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.77);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.modal__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  min-height: 100%;
}

.modal__content {
  position: relative;
  width: 100%;
  max-width: 80rem;
  background-color: var(--clr-default-100);
  opacity: 0;
  transform: translateY(-100%);
  transition: transform var(--transition), opacity var(--transition);
}

.modal__content-account {
  border-radius: 1.5rem;
  padding: 4rem;
}

.modal__close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 2rem;
  height: 2rem;
}

.modal__close::after,
.modal__close::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background-color: var(--clr-default-900);
}

.modal__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal.show {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.modal.show .modal__content {
  opacity: 1;
  transform: translateY(0);
  transition: transform var(--transition), opacity var(--transition);
}

.hero {
  position: relative;
  overflow: hidden;
  margin-top: -9rem;
  padding-top: 16rem;
  background-image: var(--clr-default-850);
}

.hero::before {
  content: "";
  position: absolute;
  right: -21rem;
  top: -27rem;
  z-index: 0;
  border-radius: 50%;
  width: 66rem;
  height: 66rem;
  background-color: var(--clr-radial-400);
  filter: blur(13rem);
}

.hero__wrapper {
  padding-bottom: 10rem;
}

.hero__content {
  z-index: 1;
  flex-shrink: 0;
  max-width: 69rem;
  gap: 10rem;
}

.hero__info {
  gap: 5rem;
}

.hero__title-wrapper {
  gap: 2.5rem;
}

.hero__title {
  color: var(--clr-default-100);
}

.hero__description {
  max-width: 49rem;
}

.hero__description p {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-primary-200);
}

.hero__buttons {
  align-items: center;
  gap: 3rem;
}

.hero__button-open {
  border-radius: 1.2rem;
  min-width: 23rem;
  min-height: 6rem;
  color: var(--clr-default-100);
  gap: 1rem;
}

.hero__button-open svg {
  width: 2rem;
  height: 2rem;
  fill: transparent;
  stroke: var(--clr-default-100);
}

.hero__devivered {
  border-radius: 1.2rem;
  padding: 1.5rem;
  width: max-content;
  min-height: 9rem;
  background-color: var(--clr-primary-300);
  gap: 1.2rem;
}

.hero__devivered-value {
  font-family: "ClashDisplay", sans-serif;
  font-weight: 400;
  font-size: clamp(
    3.2rem,
    2.8483516484rem + 1.0989010989vw,
    4.2rem
  );
  color: var(--clr-default-100);
}

.hero__devivered-text {
  max-width: 7rem;
  font-size: 1.4rem;
  line-height: 140%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 0.7);
}

.hero__images {
  position: relative;
  min-width: 65rem;
}

.hero__image-phone {
  position: absolute;
  right: 7rem;
  bottom: -62rem;
  z-index: 1;
  width: 65rem;
  height: 122rem;
}

.hero__image-bg {
  position: absolute;
  left: 50%;
  bottom: -60rem;
  z-index: 0;
  width: 122rem;
  height: 122rem;
  transform: translateX(-55%);
}

.partners {
  background-color: var(--clr-default-100);
}

.partners__list {
  display: flex;
  align-items: center;
  overflow: auto;
  margin-left: -1.5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: calc(100% + 3rem);
  white-space: nowrap;
  gap: 2rem;
}

.partners__item {
  min-width: 22rem;
}

.partners__item-image {
  display: flex;
  justify-content: center;
}

.benefits {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.benefits__wrapper {
  gap: 5rem;
}

.benefits__info {
  gap: 3rem;
}

.benefits__text {
  max-width: 79.5rem;
  gap: 4rem;
}

.benefits__button {
  height: max-content;
  min-height: 5rem;
}

.benefits__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  gap: 2rem;
}

.benefits__item {
  border-radius: 1.6rem;
  padding-top: 2.5rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
  padding-left: 2rem;
  background-color: var(--clr-default-100);
  gap: 1.5rem;
}

.benefits__item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  min-height: 12rem;
  background-color: var(--clr-primary-100);
  aspect-ratio: 16/4;
}

.benefits__item-title {
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.6593406593rem + 0.4395604396vw,
    2.2rem
  );
  line-height: 127%;
  color: var(--clr-default-950);
}

.start {
  height: 200vh;
  background: radial-gradient(48.69% 48.69% at 57.92% 51.31%, #091926 0%, #060e1d 100%), radial-gradient(48.69% 48.69% at 57.92% 51.31%, #1f2d43 0%, #151f2f 100%), #1b2534;
}

.start .container {
  height: 100%;
}

.start-title {
  font-family: "ClashDisplay", sans-serif;
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    1.4153846154rem + 3.0769230769vw,
    5.2rem
  );
  color: var(--clr-primary-700);
}

.start-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10rem 4.2rem;
  height: 100%;
}

.start-wrapper::after,
.start-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 0.1rem;
  height: 100%;
  background-color: var(--clr-default-100);
  opacity: 0.15;
  transform: translateY(-50%);
  pointer-events: none;
}

.start-wrapper::before {
  left: 0;
}

.start-wrapper::after {
  right: 0;
}

.start-content {
  position: sticky;
  top: 10rem;
  display: flex;
  flex-direction: column;
  gap: 6.2rem;
}

.start-info__item {
  position: relative;
  align-items: flex-start;
  padding: 4.6rem 4.2rem 4.6rem 0;
  min-height: 14.6rem;
  opacity: 0.45;
  transition: opacity var(--transition) var(--transition);
}

.start-info__item::before {
  content: "";
  position: absolute;
  left: -4.3rem;
  top: 0;
  width: 0.4rem;
  height: 0;
  background-color: var(--clr-default-300);
  transition: height var(--transition);
}

.start-info__item-number {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  font-weight: 500;
  color: var(--clr-default-950);
  background-color: var(--clr-default-100);
}

.start-info__item-content {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.start-info__item-title {
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.6593406593rem + 0.4395604396vw,
    2.2rem
  );
  color: var(--clr-default-100);
}

.start-info__item-subtitle {
  font-size: 1.4rem;
  color: var(--clr-primary-200);
}

.start-info__item.active {
  opacity: 1;
}

.start-info__item.active::before {
  height: 100%;
  transition: height var(--transition) var(--transition);
}

.start-info__image {
  width: 100%;
}

.start-info__wrapper {
  display: flex;
  gap: 1.6rem;
}

.start-images {
  position: sticky;
  top: 20rem;
  display: flex;
  flex-direction: column;
  transition: transform var(--transition);
  gap: 3.2rem;
}

.start-image {
  opacity: 0.45;
  transition: opacity var(--transition);
}

.start-image__wrapper {
  z-index: 1;
  max-width: 25rem;
  gap: 5rem;
}

.start-image__text {
  gap: 1rem;
}

.start-image__title {
  font-family: "Clash Display", sans-serif;
  font-size: 2.3rem;
  line-height: 140%;
  color: var(--clr-primary-700);
}

.start-image__description p {
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--clr-primary-200);
}

.start-image__button {
  min-width: 16rem;
  min-height: 5rem;
}

.start-image__phone {
  position: absolute;
  right: 5rem;
  bottom: -0.5rem;
  width: 21rem;
  height: 24.5rem;
}

.start-image__phone img {
  height: 100%;
}

.start-image--item {
  position: relative;
  overflow: hidden;
  border-radius: 1.6rem;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 4rem;
  min-height: 25rem;
  background-color: var(--clr-default-860);
}

.start-image--item::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 27rem;
  height: 23rem;
  background-image: url(../img/start/decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.start-image--item-contact::before {
  display: none;
}

.start-image--item-contact .start-image__phone {
  top: 0;
  bottom: initial;
  width: 22rem;
}

.start-image.active {
  opacity: 1;
}

.account {
  padding-top: 13rem;
}

.account__wrapper {
  position: relative;
  gap: 7rem;
}

.account__decor {
  position: absolute;
  left: -110rem;
  bottom: -70rem;
  width: 119rem;
  height: 119rem;
}

.account__item-info-wrapper {
  gap: 2rem;
}

.account__info {
  gap: 2rem;
}

.account__title {
  max-width: 59rem;
}

.account__text {
  max-width: 18.5rem;
}

.account__list {
  gap: 2.5rem;
}

.account__item {
  position: relative;
  overflow: hidden;
  border-radius: 1.6rem;
  box-shadow: var(--clr-shadow-900);
}

.account__item-inner {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 30rem;
  min-width: 100%;
  cursor: pointer;
}

.account__item--light {
  background-color: var(--clr-default-100);
}

.account__item--dark {
  background: var(--clr-default-760);
}

.account__item:focus .account__item-image {
  transform: translateX(2rem) rotate(-13deg);
}

.account__item:focus .account__item-button--light {
  color: var(--clr-primary-700);
  background-color: var(--clr-primary-800);
  transition: color var(--transition), background-color var(--transition);
}

.account__item:focus .account__item-button--dark {
  background-color: var(--clr-default-200);
  transition: background-color var(--transition);
}

.account__item-image {
  position: absolute;
  left: -10rem;
  bottom: -7rem;
  width: 34rem;
  height: 21.5rem;
  transform: translateX(0rem) rotate(-16deg);
  transition: transform var(--transition);
}

.account__item-wrapper {
  gap: 0.5rem;
}

.account__item-info {
  max-width: 28rem;
  gap: 2rem;
}

.account__item-title {
  font-family: "Clash Display", sans-serif;
  font-weight: 600;
  font-size: clamp(
    2.6rem,
    2.389010989rem + 0.6593406593vw,
    3.2rem
  );
  line-height: 119%;
}

.account__item-title--light {
  color: var(--clr-primary-400);
}

.account__item-title--dark {
  color: var(--clr-primary-700);
}

.account__item-text {
  font-size: 1.4rem;
  line-height: 140%;
}

.account__item-text--light {
  color: var(--clr-primary-600);
}

.account__item-text--dark {
  color: var(--clr-primary-200);
}

.account__item-button {
  border-radius: 0.8rem;
  min-width: 16rem;
  min-height: 4.4rem;
  max-width: 16rem;
  font-size: 1.5rem;
  transition: color var(--transition), background-color var(--transition);
}

.account__item-button--light {
  border: 0.1rem solid var(--clr-primary-800);
  color: var(--clr-default-950);
}

.account__item-button--dark {
  border: 0.1rem solid var(--clr-default-200);
  color: var(--clr-primary-700);
}

.account__item-list {
  gap: 1.5rem;
}

.account__item-li {
  position: relative;
  padding-left: 3.5rem;
  font-size: 1.8rem;
  line-height: 150%;
}

.account__item-li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0.6rem;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(../img/account/light-done.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.account__item-li--light {
  color: var(--clr-primary-400);
}

.account__item-li--dark {
  color: var(--clr-primary-700);
}

.payment {
  padding-top: 19rem;
}

.payment__wrapper {
  gap: 3rem;
}

.payment__info {
  max-width: 59rem;
  gap: 2.5rem;
}

.payment__title span {
  color: var(--clr-primary-800);
}

.payment__text {
  gap: 2rem;
}

.payment__image-wrapper {
  position: relative;
  border-radius: 2.5rem;
  min-width: 59rem;
  min-height: 37.5rem;
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-default-100);
}

.payment__image {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 53.5rem;
  height: 44rem;
  transform: translateX(-50%);
}

.ask {
  padding-top: 13rem;
}

.ask__wrapper {
  position: relative;
  z-index: 2;
  border-radius: 3rem;
  padding-top: 8rem;
  padding-right: 6rem;
  padding-bottom: 8rem;
  padding-left: 6rem;
  background-color: var(--clr-default-100);
  gap: 3rem;
}

.ask__content {
  max-width: 39rem;
  gap: 4rem;
}

.ask__title-wrapper {
  gap: 1.5rem;
}

.ask__contacts {
  gap: 4rem;
}

.ask__contact {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}

.ask__contact-title {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.ask__contact-title svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: transparent;
  stroke: var(--clr-default-300);
}

.ask__contact-title span {
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.ask__contact-text p,
.ask__contact-text a {
  font-size: 1.8rem;
  line-height: 150%;
  color: var(--clr-primary-400);
}

.ask__form-wrapper {
  min-width: 59rem;
}

.ask__form-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 2rem;
}

.ask__form {
  gap: 3rem;
}

.ask__form-textarea {
  grid-column: 1/3;
}

.ask__form-button {
  min-width: 14rem;
  min-height: 5rem;
  color: var(--clr-primary-700);
}

.iti {
  position: initial;
}

.iti__flag-container {
  border-radius: 0.8rem;
  min-width: 5rem;
  background-color: var(--clr-default-100);
}

.iti__selected-flag {
  justify-content: center;
  padding: initial;
}

.iti__arrow {
  display: none;
}

.business {
  overflow: hidden;
  margin-top: -9rem;
  padding-top: 20rem;
  padding-bottom: 16rem;
  background-image: var(--clr-default-850);
}

.business__wrapper {
  position: relative;
  gap: 3rem;
}

.business__info {
  z-index: 3;
  max-width: 76rem;
  gap: 2.5rem;
}

.business__top {
  gap: 1.5rem;
}

.business__button-border {
  min-width: 11rem;
  min-height: 4rem;
}

.business__title {
  color: var(--clr-default-100);
}

.business__bottom {
  gap: 2.5rem;
}

.business__description {
  max-width: 49rem;
}

.business__description p {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-primary-200);
}

.business__image-phone {
  position: absolute;
  right: -19rem;
  top: -5rem;
  z-index: 1;
  width: 61rem;
  height: 63.5rem;
}

.business__image-bg {
  position: absolute;
  right: -46rem;
  bottom: -44rem;
  z-index: 0;
  width: 111rem;
  height: 111rem;
}

.worldwide {
  overflow: hidden;
  padding-top: 21rem;
  padding-bottom: 16rem;
}

.worldwide__wrapper {
  position: relative;
}

.worldwide__wrapper::before {
  content: "";
  position: absolute;
  left: -10rem;
  top: -42rem;
  z-index: 0;
  width: 47rem;
  height: 111rem;
  background-image: url(../img/business/worldwide/decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.worldwide__image {
  position: absolute;
  left: 0;
  top: 50%;
  width: 77.5rem;
  height: 68.5rem;
  transform: translateY(-50%);
}

.worldwide__info {
  z-index: 1;
  margin-left: auto;
  border-radius: 2.4rem;
  padding: 4.5rem;
  max-width: 70rem;
  background-color: var(--clr-default-100);
  gap: 2.5rem;
}

.worldwide__text {
  gap: 1.5rem;
}

.payouts__wraper {
  position: relative;
  z-index: 1;
  margin-left: -11rem;
  border-radius: 2.4rem;
  padding-top: 10rem;
  padding-right: 11rem;
  padding-bottom: 10rem;
  padding-left: 11rem;
  width: calc(100% + 22rem);
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-default-100);
  gap: 5rem;
}

.payouts__text {
  margin-right: auto;
  margin-left: auto;
  max-width: 60rem;
  gap: 2.5rem;
}

.payouts__title {
  text-align: center;
}

.payouts__description p {
  text-align: center;
}

.payouts__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.payouts__item {
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;
  padding-top: 2.5rem;
  padding-right: 2rem;
  padding-bottom: 2.5rem;
  padding-left: 2rem;
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-primary-100);
  gap: 1.5rem;
}

.payouts__item--long {
  position: relative;
  flex-direction: row;
  align-items: center;
  grid-column: 1/4;
  overflow: hidden;
  padding-right: 45rem;
  gap: 3rem;
}

.payouts__item--long .payouts__item-image {
  min-width: 35rem;
}

.payouts__item--long .payouts__item-title {
  text-align: start;
}

.payouts__item--long .payouts__item-description p {
  text-align: start;
}

.payouts__item--long::before {
  content: "";
  position: absolute;
  right: -16rem;
  top: 5rem;
  width: 70rem;
  height: 8.5rem;
  background-image: url(../img/business/payouts/choice.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(-25deg);
}

.payouts__item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  background-color: var(--clr-default-100);
}

.payouts__item-text {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.payouts__item-title {
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.6593406593rem + 0.4395604396vw,
    2.2rem
  );
  text-align: center;
  color: var(--clr-primary-400);
}

.payouts__item-description {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
  text-align: center;
}

.corporate {
  overflow: hidden;
  padding-top: 13rem;
}

.corporate__wrapper {
  position: relative;
  padding-bottom: 16rem;
}

.corporate__content-wrapper {
  gap: 2.5rem;
}

.corporate__content {
  max-width: 60rem;
  gap: 5rem;
}

.corporate__text {
  gap: 2.5rem;
}

.corporate__list {
  gap: 1.5rem;
}

.corporate__item {
  position: relative;
  padding-left: 3.5rem;
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
}

.corporate__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(../img/ui/done-list.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.corporate__button {
  min-width: 16.5rem;
  min-height: 5rem;
}

.corporate__image {
  position: absolute;
  right: 0;
  bottom: -27rem;
  width: 40rem;
  height: 79rem;
}

.corporate__image-bg {
  position: absolute;
  right: -113rem;
  bottom: -78rem;
  z-index: -1;
  width: 203rem;
  height: 155rem;
}

.partner {
  position: relative;
  z-index: 2;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-image: var(--clr-default-850);
  gap: 5rem;
}

.partner__text-wrapper {
  gap: 2rem;
}

.partner__title-wrapper {
  max-width: 67rem;
  gap: 2.5rem;
}

.partner__title {
  font-family: "Clash Display", sans-serif;
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    1.4153846154rem + 3.0769230769vw,
    5.2rem
  );
  line-height: 100%;
  color: var(--clr-default-100);
}

.partner__title span {
  color: var(--clr-default-300);
}

.partner__text {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-primary-700);
}

.partner__button {
  height: max-content;
  min-width: 21rem;
  min-height: 5rem;
}

.partner__cards {
  border-top: 0.1rem solid var(--clr-default-870);
  border-bottom: 0.1rem solid var(--clr-default-870);
}

.partner__item {
  position: relative;
  padding: 3rem;
  width: 100%;
  max-width: 39rem;
  gap: 2.5rem;
}

.partner__item:not(:last-child) {
  border-right: 0.1rem solid var(--clr-default-870);
}

.partner__item:not(:last-child)::before {
  content: "";
  position: absolute;
  left: -1.5rem;
  bottom: 0;
  display: none;
  width: calc(100% + 3rem);
  height: 0.1rem;
  background-color: var(--clr-default-870);
}

.partner__item-image {
  display: flex;
  align-items: center;
  min-height: 8rem;
  max-width: 21rem;
}

.partner__item-text {
  gap: 1.2rem;
}

.partner__item-title {
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.6593406593rem + 0.4395604396vw,
    2.2rem
  );
  line-height: 127%;
  color: var(--clr-primary-700);
}

.partner__item-description {
  font-size: 1.4rem;
  line-height: 157%;
  color: var(--clr-primary-200);
}

.putting {
  padding-top: 13rem;
}

.putting__wrapper {
  gap: 10rem;
}

.putting__title {
  text-align: center;
}

.putting__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.putting__item {
  position: relative;
  border-radius: 1.6rem;
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-default-100);
}

.putting__item--long {
  justify-content: space-between;
  grid-column: 1/3;
  overflow: hidden;
  min-height: 26rem;
}

.putting__item-text {
  z-index: 2;
  padding: 2.5rem;
  max-width: 28rem;
}

.putting__item-title {
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    2.1186813187rem + 0.8791208791vw,
    3.2rem
  );
  line-height: 119%;
  color: var(--clr-default-950);
}

.putting__item-image--1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 31rem;
  height: 34rem;
}

.putting__item-image--1 img {
  width: 100%;
}

.putting__item-image--2 img {
  border-radius: 1.6rem;
  width: 100%;
  height: 100%;
}

.putting__item-image--3 {
  position: absolute;
  left: -4rem;
  bottom: 0;
  z-index: 0;
  width: 86rem;
  height: 26rem;
}

.putting__item-image--3 img {
  width: 100%;
  height: 100%;
}

.putting__item-description--long {
  margin-top: auto;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
  height: max-content;
  max-width: 35rem;
}

.open {
  position: relative;
  z-index: 1;
  padding-top: 13rem;
}

.open__wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: -17rem;
  border-radius: 3rem;
  padding-top: 6rem;
  padding-right: 8rem;
  padding-bottom: 6rem;
  padding-left: 40rem;
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-default-100);
}

.open__image {
  position: absolute;
  left: 8rem;
  bottom: -23rem;
  width: 28rem;
  height: 58rem;
}

.open__content {
  margin-left: auto;
  max-width: 67rem;
  gap: 2.5rem;
}

.open__title span {
  color: var(--clr-default-300);
}

.open__button {
  min-width: 12rem;
  min-height: 5rem;
}

.registration {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: 100vh;
}

.registration__content {
  gap: 7rem;
}

.registration__form-wrapper {
  gap: 3rem;
}

.registration__text {
  gap: 1.5rem;
}

.registration__description p {
  font-size: 1.8rem;
  line-height: 150%;
}

.registration__description a {
  font-weight: 500;
  color: var(--clr-primary-400);
  transition: color var(--transition);
}

.registration__form {
  gap: 3rem;
}

.registration__form-name {
  gap: 1.5rem;
}

.registration__form-name .form__input {
  width: 100%;
}

.registration__form-button {
  min-width: 100%;
  min-height: 5rem;
}

.registration__policy {
  gap: 3rem;
}

.registration__policy a {
  line-height: 140%;
  color: var(--clr-primary-400);
  transition: color var(--transition);
}

.registration__info {
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  padding: 3rem;
  width: 50vw;
  height: 100vh;
  min-height: 90rem;
  background-image: var(--clr-default-850);
}

.registration__image {
  position: absolute;
  left: 50%;
  top: -39rem;
  width: 92rem;
  height: 92rem;
  transform: translateX(-50%);
}

.registration__text-info {
  margin-top: auto;
  gap: 6rem;
}

.registration__text-wraper {
  margin-right: auto;
  margin-left: auto;
  max-width: 67rem;
  gap: 2.5rem;
}

.registration__text-title {
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    1.4153846154rem + 3.0769230769vw,
    5.2rem
  );
  line-height: 100%;
  color: var(--clr-primary-700);
}

.registration__text-title span {
  color: var(--clr-default-300);
}

.registration__text-info {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
  line-height: 150%;
  color: var(--clr-primary-700);
}

.registration__copyright {
  margin-left: auto;
}

.registration__copyright p {
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--clr-primary-200);
}

.online {
  overflow: hidden;
  margin-top: -9rem;
  padding-top: 14rem;
  background-image: var(--clr-default-850);
}

.online__wrapper {
  position: relative;
}

.online__info {
  position: relative;
  z-index: 2;
  gap: 1.5rem;
}

.online__button-card {
  min-height: 4rem;
}

.online__title {
  text-align: center;
  color: var(--clr-primary-700);
}

.online__button-account {
  border-radius: 1.2rem;
  min-width: 23rem;
  min-height: 6rem;
  color: var(--clr-default-100);
  gap: 1rem;
}

.online__button-account svg {
  width: 2rem;
  height: 2rem;
  fill: transparent;
  stroke: var(--clr-default-100);
}

.online__cards {
  margin-top: 5rem;
}

.online__bg {
  position: absolute;
  left: 50%;
  bottom: -60rem;
  z-index: 0;
  width: 126rem;
  height: 126rem;
  transform: translateX(-50%);
}

.use {
  position: relative;
  z-index: 2;
  margin-top: -5.5rem;
  padding-top: 13rem;
  background-color: var(--clr-primary-100);
}

.use__wrapper {
  gap: 5rem;
}

.use__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.use__item {
  position: relative;
  overflow: hidden;
  border-radius: 1.6rem;
  padding: 2.5rem;
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-default-100);
}

.use__item--1 .use__item-image {
  position: absolute;
  right: 3rem;
  bottom: -12rem;
  width: 38rem;
  height: 35rem;
}

.use__item--2 .use__item-image {
  position: absolute;
  right: -6rem;
  bottom: -13rem;
  width: 45.5rem;
  height: 49rem;
  transform: rotate(22deg);
}

.use__item--3 .use__item-image {
  position: absolute;
  right: -14.5rem;
  top: 50%;
  width: 47rem;
  height: 34rem;
  transform: translateY(-50%);
}

.use__item--long {
  grid-column: 1/3;
  min-height: 26rem;
}

.use__item--short {
  flex-direction: column;
  justify-content: space-between;
  grid-column: 3/4;
  grid-row: 1/3;
  gap: 2rem;
}

.use__item-text {
  max-width: 31rem;
}

.use__item-title {
  font-family: "Clash Display", sans-serif;
  font-weight: 500;
  font-size: clamp(
    2.4rem,
    2.1186813187rem + 0.8791208791vw,
    3.2rem
  );
  line-height: 119%;
  color: var(--clr-default-950);
}

.use__item-description p {
  font-size: 1.4rem;
  line-height: 140%;
}

.get {
  padding-top: 13rem;
}

.get__wrapper {
  gap: 2rem;
}

.get__content {
  width: 50%;
  gap: 4rem;
}

.get__text {
  gap: 2.5rem;
}

.get__list {
  gap: 2rem;
}

.get__item {
  border-radius: 1.6rem;
  padding: 1.5rem;
  background-color: var(--clr-default-100);
  gap: 3rem;
}

.get__item-text {
  gap: 0.5rem;
}

.get__item-image {
  min-height: 12rem;
}

.get__item-title {
  text-align: center;
}

.get__item-description p {
  text-align: center;
}

.get__form {
  border-radius: 1.6rem;
  padding: 4rem;
  width: 50%;
  background-color: var(--clr-default-100);
}

.get__form-wrapper {
  justify-content: space-between;
  min-height: 100%;
  gap: 3rem;
}

.get__select-body {
  gap: 3rem;
}

.get__select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.get__select-wrapper span {
  line-height: 140%;
}

.get__select-sign {
  padding-right: 1.2rem;
  line-height: 140%;
  color: var(--clr-primary-450);
}

.get__input-currency {
  outline: none;
}

.get__input-currency::-webkit-outer-spin-button,
.get__input-currency::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.get__select-currency {
  display: flex;
  justify-content: space-between;
  border: 0.1rem solid var(--clr-default-770);
  border-radius: 0.8rem;
  padding-left: 1.2rem;
  min-height: 5rem;
  background-color: var(--clr-primary-100);
  transition: border-color var(--transition);
}

.get__select-currency:focus-within {
  border-color: var(--clr-default-200);
  transition: border-color var(--transition);
}

.get__select-currency .is-open .choices__inner {
  border-radius: 0.8rem;
}

.get__select-currency .choices__list--dropdown {
  top: 7rem;
  border: 0.1rem solid var(--clr-default-770);
  border-radius: 0.8rem;
  background-color: var(--clr-primary-100);
}

.get__select-currency .choices__inner {
  border: initial;
  background-color: var(--clr-default-100);
}

.get__select-currency .choices {
  min-width: 12rem;
}

.get__select-currency .select__wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.get__select-currency .select__text {
  text-transform: uppercase;
  color: var(--clr-default-950);
}

.get__select-currency .choices__list--dropdown .choices__item--selectable,
.get__select-currency .choices__list[aria-expanded] .choices__item--selectable {
  padding-right: 1rem;
}

.get__select-currency .choices[data-type*=select-one]::after {
  content: "";
  height: 0.6rem;
  width: 1.2rem;
  background-image: url(../img/ui/select-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border-style: initial;
  border-color: initial;
  border-width: initial;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
  transition: transform var(--transition);
}

.get__select-currency .choices[data-type*=select-one].is-open::after {
  transform: rotate(-180deg);
  transition: transform var(--transition);
}

.get__select-currency .is-open .get__select-currency {
  border: 0.1rem solid var(--clr-default-200);
  border-radius: 0.8rem;
  transition: border var(--transition);
}

.get__select-country .choices__list--dropdown {
  top: 7rem;
  border: 0.1rem solid var(--clr-default-770);
  border-radius: 0.8rem;
  background-color: var(--clr-primary-100);
}

.get__select-country .choices__item {
  display: flex;
  align-items: center;
  line-height: 1;
}

.get__select-country .choices__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.get__select-country .is-open .choices__inner {
  border: 0.1rem solid var(--clr-default-200);
  border-radius: 0.8rem;
  transition: border var(--transition);
}

.get__select-country .choices__inner {
  position: relative;
  border: 0.1rem solid var(--clr-default-770);
  background-color: var(--clr-primary-100);
  transition: border var(--transition);
}

.get__select-country .choices[data-type*=select-one]::after {
  content: "";
  height: 0.6rem;
  width: 1.2rem;
  background-image: url(../img/ui/select-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border-style: initial;
  border-color: initial;
  border-width: initial;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
  transition: transform var(--transition);
}

.get__select-country .choices[data-type*=select-one].is-open::after {
  transform: rotate(-180deg);
  transition: transform var(--transition);
}

.get__select-country .select__wrapper {
  display: flex;
  align-items: center;
}

.get__select-country .select__image {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  width: 5.5rem;
  height: 100%;
  background-color: #fff;
}

.get__select-country .select__text {
  padding-left: 5rem;
}

.get__input-currency {
  width: 100%;
  background-color: transparent;
}

.get__separator {
  width: 100%;
  height: 0.1rem;
  background-color: var(--clr-primary-500);
}

.get__info {
  justify-content: space-between;
}

.get__info-values {
  align-items: center;
  gap: 2.5rem;
}

.get__info-value {
  gap: 0.5rem;
}

.get__info-title {
  line-height: 140%;
}

.get__info-span {
  font-family: "Clash Display", sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  color: var(--clr-primary-400);
}

.get__info-separator {
  width: 0.1rem;
  height: 80%;
  background-color: var(--clr-primary-500);
}

.get__button {
  min-width: 19rem;
}

.secure {
  overflow: hidden;
  margin-top: 13rem;
  background-color: var(--clr-default-100);
}

.secure__wraper {
  position: relative;
  padding-top: 12rem;
  padding-bottom: 12rem;
  gap: 3rem;
}

.secure__content {
  max-width: 59rem;
  gap: 5rem;
}

.secure__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.secure__item {
  gap: 2.5rem;
}

.secure__item-done {
  position: relative;
  width: 4rem;
  height: 3rem;
}

.secure__item-done::before,
.secure__item-done::after {
  content: "";
  position: absolute;
  border-radius: 0.8rem;
}

.secure__item-done::before {
  left: 0.7rem;
  bottom: 0;
  width: 0.2rem;
  height: 1.7rem;
  background-color: var(--clr-default-300);
  transform: rotate(-45deg);
}

.secure__item-done::after {
  right: 1.4rem;
  top: 0.4rem;
  width: 0.2rem;
  height: 2.3rem;
  background-color: var(--clr-default-200);
  transform: rotate(45deg);
}

.secure__item-content {
  gap: 1.2rem;
}

.secure__item-title {
  font-weight: 500;
  font-size: 2.2rem;
  color: var(--clr-default-950);
}

.secure__item-text p {
  font-size: 1.4rem;
  line-height: 157%;
}

.secure__image {
  position: absolute;
  right: -25rem;
  bottom: 0;
  width: 82rem;
  height: 66rem;
}

.commission {
  padding-top: 13rem;
}

.commission__wrapper {
  position: relative;
  gap: 4rem;
}

.commission__decor {
  position: absolute;
  left: -130rem;
  bottom: -50rem;
  z-index: 0;
  width: 159rem;
  height: 159rem;
}

.commission__text {
  margin-right: auto;
  margin-left: auto;
  max-width: 68rem;
  gap: 2.5rem;
}

.commission__title {
  text-align: center;
}

.commission__description p {
  text-align: center;
}

.commission__table {
  z-index: 2;
  overflow: auto;
  border: 0.1rem solid var(--clr-default-740);
  border-radius: 0.8rem;
  box-shadow: var(--clr-shadow-900);
  background-color: var(--clr-default-100);
}

.commission__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.commission__table table thead {
  background-color: var(--clr-default-740);
}

.commission__table table thead tr th {
  padding-top: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 2.5rem;
  height: 5rem;
  text-align: start;
}

.commission__table table thead tr th:first-child {
  max-width: 64rem;
}

.commission__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--clr-default-740);
}

.commission__table table tbody td {
  padding-top: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 2.5rem;
  height: 7rem;
  font-size: 1.8rem;
}

.commission__table table tbody td:first-child {
  font-weight: 500;
  color: var(--clr-default-950);
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable,
  .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }

  .choices__list--dropdown .choices__item--selectable::after,
  .choices__list[aria-expanded] .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  [dir=rtl] .choices__list--dropdown .choices__item--selectable,
  [dir=rtl] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }

  [dir=rtl] .choices__list--dropdown .choices__item--selectable::after,
  [dir=rtl] .choices__list[aria-expanded] .choices__item--selectable::after {
    right: auto;
    left: 10px;
  }
}

@media (max-width: 105em) {
  .business {
    padding-top: 15rem;
    padding-bottom: 10rem;
  }

  .business__info {
    max-width: 70rem;
  }

  .business__image-phone {
    position: relative;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 45rem;
  }

  .business__image-bg {
    right: -25rem;
    bottom: -36rem;
    width: 90rem;
    height: 90rem;
  }
}

@media (max-width: 95em) {
  .registration__image {
    top: -17rem;
    width: 65rem;
    height: 65rem;
  }
}

@media (max-width: 85em) {
  .secure__wraper {
    padding-bottom: initial;
  }

  .secure__image {
    position: relative;
    right: initial;
    bottom: initial;
    width: initial;
    height: initial;
  }
}

@media (max-width: 80em) {
  .footer__image {
    right: -30rem !important;
  }

  .hero__content {
    flex-shrink: initial;
  }

  .hero__images {
    min-width: initial;
  }

  .hero__image-phone {
    position: relative;
    right: initial;
    bottom: initial;
    width: initial;
    height: initial;
    max-width: 40rem;
  }

  .hero__image-bg {
    bottom: -34rem;
    width: 80rem;
    height: 100rem;
    transform: translateX(-45%);
  }

  .payment {
    padding-top: 12rem;
  }

  .payment__info {
    max-width: initial;
  }

  .payment__image-wrapper {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    min-width: 43rem;
  }

  .payment__image {
    position: relative;
    left: initial;
    bottom: -2rem;
    width: initial;
    height: initial;
    transform: initial;
  }

  .business__info {
    max-width: 60rem;
  }

  .business__image-phone {
    max-width: 50rem;
  }

  .business__image-bg {
    right: -30rem;
    bottom: -33rem;
    width: 80rem;
    height: 90rem;
  }
}

@media (max-width: 75em) {
  .use__item--1 .use__item-image {
    right: -7rem;
  }

  .use__item--2 .use__item-image {
    right: -19.5rem;
  }
}

@media (max-width: 62em) {
  .menu::before {
    display: none;
  }

  .menu__list {
    flex-direction: column;
    align-items: start;
    margin: auto;
    padding-top: 5rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    min-width: 100%;
  }

  .menu__item a {
    font-weight: 600;
    font-size: 2rem;
  }

  .menu__item--enter {
    display: block;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-image: var(--clr-default-850);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-primary-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__wrapper {
    gap: 1rem;
  }

  .header__enter-list::before {
    display: none;
  }

  .header__enter-list {
    flex-direction: column;
    align-items: initial;
    padding-left: initial;
  }

  .header__enter-item a {
    font-weight: 600;
    font-size: 2rem;
  }

  .header__menu-wrapper {
    margin-left: auto;
  }

  .header__separator {
    display: none;
  }

  .footer {
    padding-top: 16rem;
    padding-bottom: 8rem;
  }

  .footer__info {
    max-width: initial;
  }

  .footer__image {
    display: none;
  }

  .hero {
    padding-top: 14rem;
  }

  .hero__wrapper {
    flex-direction: column;
    padding-bottom: 8rem;
    gap: 2rem;
  }

  .hero__content {
    max-width: initial;
    gap: 5rem;
  }

  .hero__description {
    max-width: initial;
  }

  .hero__image-phone {
    margin-right: auto;
    margin-left: auto;
    max-width: 30rem;
  }

  .hero__image-bg {
    bottom: -10rem;
    width: 100%;
    height: 60rem;
    transform: translateX(-50%);
  }

  .benefits {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .start {
    height: initial;
  }

  .start-wrapper {
    padding: 5rem 1.5rem;
  }

  .start-content {
    position: initial;
    top: initial;
  }

  .start-info__item {
    display: flex;
    flex-direction: column;
    padding: initial !important;
    opacity: 1;
    gap: 2rem;
  }

  .start-info__wrapper {
    flex-direction: column;
  }

  .start-info {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .start-images {
    display: none;
  }

  .start-image {
    opacity: initial;
  }

  .account {
    padding-top: 10rem;
  }

  .account__wrapper {
    gap: 4rem;
  }

  .account__info {
    flex-direction: column;
  }

  .account__title {
    max-width: initial;
  }

  .account__text {
    max-width: initial;
  }

  .account__item-inner {
    padding-left: 20rem;
  }

  .account__item-image {
    left: -18rem;
    bottom: -4rem;
  }

  .payment__wrapper {
    flex-direction: column;
  }

  .payment__image-wrapper {
    min-height: initial;
  }

  .payment__image {
    bottom: 0;
  }

  .ask {
    padding-top: 8rem;
  }

  .ask__wrapper {
    flex-direction: column;
    margin-bottom: initial;
    gap: 5rem;
  }

  .ask__content {
    max-width: initial;
  }

  .ask__contacts {
    gap: 2rem;
  }

  .ask__contact {
    gap: 1rem;
  }

  .ask__form-wrapper {
    min-width: initial !important;
  }

  .business {
    padding-top: 12rem;
    padding-bottom: 5rem;
  }

  .business__wrapper {
    flex-direction: column;
  }

  .business__info {
    max-width: initial;
  }

  .business__description {
    max-width: initial;
  }

  .business__images {
    position: relative;
  }

  .business__image-phone {
    margin-right: auto;
    margin-left: auto;
  }

  .business__image-bg {
    left: 50%;
    right: initial;
    top: 50%;
    bottom: initial;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  .business__image-bg img {
    height: 100%;
    object-fit: contain;
  }

  .worldwide {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }

  .worldwide__wrapper::before {
    display: none;
  }

  .worldwide__wrapper {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .worldwide__image {
    position: initial;
    left: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 55rem;
    transform: initial;
  }

  .worldwide__info {
    padding: 3rem;
    max-width: initial;
  }

  .payouts__item--long::before {
    display: none;
  }

  .payouts__item--long {
    padding-right: 3rem;
  }

  .corporate {
    padding-top: 8rem;
  }

  .corporate__wrapper {
    flex-direction: column;
    padding-bottom: initial;
    gap: 3rem;
  }

  .corporate__content {
    max-width: initial !important;
  }

  .corporate__image {
    display: none;
  }

  .corporate__image-bg {
    display: none;
  }

  .partner {
    margin-top: 6rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .partner__text-wrapper {
    flex-direction: column;
  }

  .partner__title-wrapper {
    max-width: initial;
  }

  .partner__item {
    padding: 2rem;
  }

  .putting__item--long {
    flex-direction: column;
    justify-content: initial;
    min-height: initial;
  }

  .putting__item {
    padding: 2rem;
  }

  .putting__item-text--2 {
    max-width: initial !important;
  }

  .putting__item-text {
    padding: initial;
    max-width: initial;
    gap: 2rem;
  }

  .putting__item-image {
    display: none;
  }

  .putting__item-description--long {
    margin-top: initial;
    padding-right: initial;
    padding-bottom: initial;
    max-width: initial !important;
  }

  .open {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .open__wrapper {
    padding: 3rem;
    min-height: 60rem;
    gap: 3rem;
  }

  .open__image {
    left: 50%;
    transform: translateX(-50%);
  }

  .open__content {
    max-width: initial;
    padding-bottom: 32rem;
  }

  .registration__wrapper {
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
  }

  .registration__content {
    gap: 3rem;
  }

  .registration__form-wrapper {
    gap: 2rem;
  }

  .registration__form-name {
    justify-content: space-between;
  }

  .registration__info {
    position: relative;
    left: -1.5rem;
    right: initial;
    top: initial;
    width: calc(100% + 3rem);
    height: initial;
    min-width: initial;
    min-height: initial;
  }

  .registration__image {
    display: none;
  }

  .registration__text-info {
    gap: 3rem;
  }

  .registration__text-wraper {
    max-width: initial;
  }

  .online {
    padding-top: 12rem;
  }

  .online__cards {
    margin-top: 3rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .use {
    padding-top: 8rem;
  }

  .use__list {
    display: flex;
    flex-direction: column;
  }

  .use__item--2 .use__item-image {
    right: -11rem;
    width: 41rem;
    height: 31rem;
    transform: initial;
  }

  .use__item--3 .use__item-image {
    right: -14.5rem;
    top: initial;
    bottom: -6rem;
    width: 35rem;
    height: 25rem;
    transform: initial;
  }

  .use__item--long {
    min-height: initial;
  }

  .use__item-text {
    max-width: initial;
    gap: 3rem;
  }

  .use__item-description-last {
    display: none;
  }

  .get {
    padding-top: 8rem;
  }

  .get__wrapper {
    flex-direction: column;
    gap: 5rem;
  }

  .get__content {
    width: initial;
  }

  .get__list {
    flex-direction: column;
  }

  .get__form {
    width: initial;
  }

  .secure__wraper {
    flex-direction: column;
  }

  .secure__content {
    max-width: initial;
  }

  .secure__image {
    display: flex;
    justify-content: center;
  }

  .commission {
    padding-top: 8rem;
  }

  .commission__decor {
    display: none;
  }
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

@media (max-width: 108.75em) {
  .footer__image {
    right: -7rem;
    bottom: -9rem;
    width: 70rem;
    height: 70rem;
  }
}

@media (max-width: 98.75em) {
  .registration__info {
    justify-content: center;
  }
}

@media (max-width: 93.75em) {
  .start-info__item {
    padding: 3.2rem 4.2rem 3.2rem 0;
    min-height: 11.6rem;
  }
}

@media (max-width: 91.25em) {
  .payouts__wraper {
    margin-left: initial;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: initial;
  }
}

@media (max-width: 86.25em) {
  .online__bg {
    bottom: -75rem;
  }
}

@media (max-width: 73.75em) {
  .ask__wrapper {
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
  }

  .ask__form-wrapper {
    min-width: 50rem;
  }

  .worldwide__image {
    left: -12rem;
  }

  .putting__item-text--2 {
    max-width: 24rem;
  }

  .putting__item-image--1 {
    width: 23rem;
    height: 26rem;
  }

  .putting__item-image--3 {
    left: -19rem;
  }

  .putting__item-description--long {
    max-width: 25rem;
  }
}

@media (max-width: 67.5em) {
  .menu__list {
    gap: 2rem;
  }

  .header__enter-list {
    padding-left: 1rem;
  }

  .benefits__info {
    flex-direction: column;
  }

  .benefits__text {
    max-width: initial;
  }

  .start-image__wrapper {
    max-width: 20rem;
  }

  .start-image__phone {
    right: 2rem;
  }

  .corporate__content {
    max-width: 55rem;
  }
}

@media (max-width: 47.9375em) {
  .footer {
    padding-bottom: 6rem;
  }

  .benefits {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .benefits__wrapper {
    gap: 3rem;
  }

  .start-image__phone {
    right: 1rem;
  }

  .start-image--item {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .account {
    padding-top: 5rem;
  }

  .account__item-info-wrapper {
    flex-direction: column;
  }

  .ask__wrapper {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .ask__form-inner {
    gap: 2rem;
  }

  .payouts__wraper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    gap: 3rem;
  }

  .payouts__item--long .payouts__item-image {
    min-width: 25rem;
  }

  .payouts__item {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
  }

  .partner__list {
    flex-direction: column;
  }

  .partner__item:not(:last-child)::before {
    display: block;
  }

  .partner__item:not(:last-child) {
    border-right: initial;
  }

  .partner__item {
    padding-top: 2rem;
    padding-right: initial;
    padding-bottom: 2rem;
    padding-left: initial;
    max-width: initial;
    gap: 1rem;
  }

  .putting {
    padding-top: 8rem;
  }

  .putting__wrapper {
    gap: 5rem;
  }

  .putting__list {
    grid-template-columns: 1fr;
  }

  .putting__item--long {
    grid-column: initial;
  }

  .open {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .open__wrapper {
    padding: 2rem;
  }

  .open__content {
    gap: 1.5rem;
  }

  .online__bg {
    bottom: -85rem;
  }

  .use__item--1 .use__item-image {
    left: 0;
    right: initial;
    width: 30rem;
    height: 30rem;
  }

  .use__item--2 .use__item-image {
    left: 0;
    right: initial;
    bottom: -11rem;
  }

  .use__item {
    padding: initial;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 20rem;
    padding-left: 2rem;
  }

  .use__item-text {
    gap: 2rem;
  }

  .get__wrapper {
    gap: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .button {
    min-width: 100%;
  }

  .menu__item--btns {
    display: block;
    min-width: 100%;
  }

  .header__buttons {
    flex-direction: column;
    align-items: start;
    gap: 3rem;
  }

  .header__our {
    font-weight: 600;
    font-size: 2rem;
  }

  .header__button-registration {
    min-width: 100%;
  }

  .footer {
    padding-bottom: 4rem;
  }

  .footer__top {
    justify-content: space-between;
  }

  .footer__policy {
    justify-content: space-between;
    gap: 2rem;
  }

  .modal__content-account {
    padding-top: 3rem;
    padding-right: 1.5rem;
    padding-bottom: 3rem;
    padding-left: 1.5rem;
  }

  .hero {
    padding-top: 12rem;
  }

  .hero__wrapper {
    padding-bottom: 5rem;
  }

  .hero__buttons {
    flex-direction: column;
    align-items: start;
  }

  .hero__image-bg {
    width: 60rem;
    height: 60rem;
  }

  .partners__list {
    grid-template-columns: 1fr 1fr;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 1.5rem;
  }

  .benefits__text {
    gap: 2rem;
  }

  .benefits__item {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
  }

  .start-wrapper {
    padding: 5rem 0rem;
  }

  .start-wrapper::before,
  .start-wrapper::after {
    display: none;
  }

  .start-content {
    gap: 3rem;
  }

  .start-image__wrapper {
    max-width: 18rem;
    gap: 3rem;
  }

  .start-image__phone {
    bottom: -10rem;
    width: 13rem;
  }

  .start-image__phone img {
    height: initial;
    object-fit: initial;
  }

  .start-image--item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    min-height: initial;
  }

  .start-image--item-contact .start-image__phone {
    right: 0;
    width: 17rem;
  }

  .account__item-inner {
    padding-left: 9rem;
  }

  .account__item:focus .account__item-image {
    transform: translateY(-50%) translateX(-2rem) rotate(90deg);
    transition: transform var(--transition);
  }

  .account__item-image {
    left: -21rem;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }

  .account__item-info {
    max-width: initial;
  }

  .payment__wrapper {
    gap: 2rem;
  }

  .payment__info {
    gap: 2rem;
  }

  .payment__text {
    gap: 1.5rem;
  }

  .payment__image-wrapper {
    min-width: 100%;
  }

  .ask {
    padding-top: 6rem;
  }

  .ask__form-inner {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .ask__form-textarea {
    grid-column: initial;
  }

  .worldwide {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .worldwide__info {
    padding: 1.5rem;
  }

  .payouts__list {
    grid-template-columns: 1fr;
  }

  .payouts__item--long .payouts__item-image {
    min-width: 100%;
  }

  .payouts__item--long .payouts__item-title {
    text-align: center;
  }

  .payouts__item--long .payouts__item-description p {
    text-align: center;
  }

  .payouts__item--long {
    flex-direction: column;
    grid-column: initial;
    gap: 1.5rem;
  }

  .corporate {
    padding-top: 6rem;
  }

  .corporate__content {
    gap: 3rem;
  }

  .partner {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .putting {
    padding-top: 5rem;
  }

  .putting__wrapper {
    gap: 3rem;
  }

  .registration__form-name {
    flex-direction: column;
  }

  .registration__policy {
    justify-content: space-between;
  }

  .registration__info {
    padding: 1.5rem;
  }

  .use__wrapper {
    gap: 4rem;
  }

  .get {
    padding-top: 5rem;
  }

  .get__form {
    padding: 2rem;
  }

  .get__info {
    flex-direction: column;
    gap: 2.5rem;
  }

  .get__info-separator {
    height: 4.5rem;
  }

  .get__button {
    min-height: 6rem;
  }

  .secure {
    margin-top: 5rem;
  }

  .secure__wraper {
    padding-top: 5rem;
  }

  .secure__list {
    display: flex;
    flex-direction: column;
  }

  .commission {
    padding-top: 5rem;
  }
}

@media (max-width: 23.4375em) {
  .start-image__wrapper {
    max-width: initial;
  }

  .start-image__phone {
    display: none;
  }

  .account__item-inner {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
  }

  .account__item-image {
    display: none;
  }
}

@media (hover) {
  .button--blue:hover {
    background-color: var(--clr-hover-300);
    transition: background-color var(--transition);
  }

  .button--dark:hover {
    box-shadow: var(--clr-shadow-700);
    transition: box-shadow var(--transition);
  }

  .button--border-green:hover {
    box-shadow: var(--clr-shadow-800);
    transition: box-shadow var(--transition);
  }

  .typography .link:hover {
    color: var(--clr-secondary-400);
  }

  .menu__item a:hover {
    color: var(--clr-default-100);
    transition: color var(--transition);
  }

  .menu__item a:hover::before {
    width: 60%;
    transition: width 0.3s;
  }

  .header__enter-item a:hover::before {
    width: 60%;
    transition: width 0.3s;
  }

  .header__our:hover {
    color: var(--clr-default-100);
    transition: color var(--transition);
  }

  .header__our:hover::before {
    width: 60%;
    transition: width 0.3s;
  }

  .header__button-registration:hover {
    border: 0.1rem solid var(--clr-default-300);
    color: var(--clr-default-300);
    transition: border var(--transition), color var(--transition);
  }

  .footer__link a:hover {
    background-color: var(--clr-hover-300);
    transition: background-color var(--transition);
  }

  .footer__policy-item a:hover {
    color: var(--clr-default-300);
    transition: color var(--transition);
  }

  .account__item:hover .account__item-image {
    transform: translateX(3rem) rotate(-13deg);
    transition: transform var(--transition);
  }

  .account__item:hover .account__item-button--light {
    color: var(--clr-primary-700);
    background-color: var(--clr-primary-800);
    transition: color var(--transition), background-color var(--transition);
  }

  .account__item:hover .account__item-button--dark {
    background-color: var(--clr-default-200);
    transition: background-color var(--transition);
  }

  .registration__description a:hover {
    color: var(--clr-default-200);
    transition: color var(--transition);
  }

  .registration__policy a:hover {
    color: var(--clr-default-200);
    transition: color var(--transition);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }

  .iti__flag {
    background-image: url("../img/ui/flags/flags@2x.png");
  }
}