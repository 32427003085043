.business {
  overflow: hidden;
  margin-top: -9rem;
  padding-top: 20rem;
  padding-bottom: 16rem;
  background-image: var(--clr-default-850);

  @media (max-width: em(1680)) {
    padding-top: 15rem;
    padding-bottom: 10rem;
  }

  @media (max-width: em(992)) {
    padding-top: 12rem;
    padding-bottom: 5rem;
  }

  &__wrapper {
    position: relative;
    gap: 3rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    z-index: 3;
    max-width: 76rem;
    gap: 2.5rem;

    @media (max-width: em(1680)) {
      max-width: 70rem;
    }

    @media (max-width: em(1280)) {
      max-width: 60rem;
    }

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__top {
    gap: 1.5rem;
  }

  &__button-border {
    min-width: 11rem;
    min-height: 4rem;
  }

  &__title-wrapper {
  }

  &__title {
    color: var(--clr-default-100);
  }

  &__bottom {
    gap: 2.5rem;
  }

  &__description {
    max-width: 49rem;
    p {
      font-size: fluid-font(1.8rem, 1.6rem);
      line-height: 150%;
      color: var(--clr-primary-200);
    }

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__button-open {
  }

  &__images {
    @media (max-width: em(992)) {
      position: relative;

    }
  }

  &__image-phone {
    position: absolute;
    right: -19rem;
    top: -5rem;
    z-index: 1;
    width: 61rem;
    height: 63.5rem;

    @media (max-width: em(1680)) {
      position: relative;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 45rem;
    }

    @media (max-width: em(1280)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__image-bg {
    position: absolute;
    right: -46rem;
    bottom: -44rem;
    z-index: 0;
    width: 111rem;
    height: 111rem;

    @media (max-width: em(1680)) {
      right: -25rem;
      bottom: -36rem;
      width: 90rem;
      height: 90rem;
    }

    @media (max-width: em(1280)) {
      right: -30rem;
      bottom: -33rem;
      width: 80rem;
      height: 90rem;
    }

    @media (max-width: em(992)) {
      left: 50%;
      right: initial;
      top: 50%;
      bottom: initial;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
