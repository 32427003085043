.start {
  height: 200vh;
  background: radial-gradient(
      48.69% 48.69% at 57.92% 51.31%,
      #091926 0%,
      #060e1d 100%
    ),
    radial-gradient(48.69% 48.69% at 57.92% 51.31%, #1f2d43 0%, #151f2f 100%),
    #1b2534;

  @media (max-width: em(992)) {
    height: initial;
  }

  .container {
    height: 100%;
  }

  &-title {
    font-family: "ClashDisplay", sans-serif;
    font-weight: 500;
    font-size: fluid-font(5.2rem, 2.4rem);
    color: var(--clr-primary-700);
  }

  &-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10rem 4.2rem;
    height: 100%;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      width: 0.1rem;
      height: 100%;
      background-color: var(--clr-default-100);
      opacity: 0.15;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    @media (max-width: em(992)) {
      padding: 5rem 1.5rem;
    }

    @media (max-width: em(575)) {
      padding: 5rem 0rem;

      &::before, &::after {
        display: none;
      }
    }
  }

  &-content {
    position: sticky;
    top: 10rem;
    display: flex;
    flex-direction: column;
    gap: 6.2rem;

    @media (max-width: em(992)) {
      position: initial;
      top: initial;
    }

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &-info {
    &__item {
      position: relative;
      align-items: flex-start;
      padding: 4.6rem 4.2rem 4.6rem 0;
      min-height: 14.6rem;
      opacity: 0.45;
      transition: opacity var(--transition) var(--transition);

      &::before {
        content: "";
        position: absolute;
        left: -4.3rem;
        top: 0;
        width: 0.4rem;
        height: 0;
        background-color: var(--clr-default-300);
        transition: height var(--transition);
      }

      &-number {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 3.2rem;
        height: 3.2rem;
        font-weight: 500;
        color: var(--clr-default-950);
        background-color: var(--clr-default-100);
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
      }

      &-title {
        font-weight: 500;
        font-size: fluid-font(2.2rem, 1.8rem);
        color: var(--clr-default-100);
      }

      &-subtitle {
        font-size: 1.4rem;
        color: var(--clr-primary-200);
      }

      &.active {
        opacity: 1;

        &::before {
          height: 100%;
          transition: height var(--transition) var(--transition);
        }
      }

      @media (max-width: em(1500)) {
        padding: 3.2rem 4.2rem 3.2rem 0;
        min-height: 11.6rem;
      }

      @media (max-width: em(992)) {
        display: flex;
        flex-direction: column;
        padding: initial !important;
        opacity: 1;
        gap: 2rem;
      }
    }

    &__image {
      width: 100%;
    }

    &__wrapper {
      display: flex;
      gap: 1.6rem;

      @media (max-width: em(992)) {
        flex-direction: column;
      }
    }

    @media (max-width: em(992)) {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }

  &-images {
    position: sticky;
    top: 20rem;
    display: flex;
    flex-direction: column;
    transition: transform var(--transition);
    gap: 3.2rem;

    @media (max-width: em(992)) {
     display: none;
    }
  }

  &-image {
    opacity: 0.45;
    transition: opacity var(--transition);

    @media (max-width: em(992)) {
      opacity: initial;
    }

    &__wrapper {
      z-index: 1;
      max-width: 25rem;
      gap: 5rem;

      @media (max-width: em(1080)) {
        max-width: 20rem;
      }

      @media (max-width: em(575)) {
        max-width: 18rem;
        gap: 3rem;
      }

      @media (max-width: em(375)) {
        max-width: initial;
      }
    }

    &__text {
      gap: 1rem;
    }

    &__title {
      font-family: 'Clash Display', sans-serif;
      font-size: 2.3rem;
      line-height: 140%;
      color: var(--clr-primary-700);
    }

    &__description {
      p {
        font-size: 1.4rem;
        line-height: 140%;
        color: var(--clr-primary-200);
      }
    }

    &__button {
      min-width: 16rem;
      min-height: 5rem;
    }

    &__phone {
      position: absolute;
      right: 5rem;
      bottom: -0.5rem;
      width: 21rem;
      height: 24.5rem;

      img {
        height: 100%;
      }

      @media (max-width: em(1080)) {
        right: 2rem;
      }

      @media (max-width: em(767)) {
        right: 1rem;
      }

      @media (max-width: em(575)) {
        bottom: -10rem;
        width: 13rem;

        img {
          height: initial;
          object-fit: initial;
        }
      }

      @media (max-width: em(375)) {
        display: none;
      }
    }

    &--item {
      position: relative;
      overflow: hidden;
      border-radius: 1.6rem;
      padding-top: 3rem;
      padding-right: 3rem;
      padding-bottom: 3rem;
      padding-left: 4rem;
      min-height: 25rem;
      background-color: var(--clr-default-860);

      @media (max-width: em(767)) {
        padding-top: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
      }

      @media (max-width: em(575)) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        min-height: initial;
      }

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 27rem;
        height: 23rem;
        background-image: url(../img/start/decor.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }

    &-contact {
      &::before {
        display: none;
      }
      .start-image__phone {
        top: 0;
        bottom: initial;
        width: 22rem;

        @media (max-width: em(575)) {
          right: 0;
          width: 17rem;
        }
      }
    }
    }

    &.active {
      opacity: 1;
    }
  }
}
