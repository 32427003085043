.typography {
  &.h1 {
    font-family: 'ClashDisplay', sans-serif;
    font-weight: 500;
    font-size: fluid-font(8.2rem, 3.2rem);
    line-height: 100%;
  }

  &.h2 {
    font-family: 'ClashDisplay', sans-serif;
    font-weight: 500;
    font-size: fluid-font(5.2rem, 2.4rem);
    line-height: 100%;
    color: var(--clr-default-950);
  }

  &.h3 {
    font-size: 2.2rem;
  }

  &.h4 {
    font-size: 1.9rem;
  }

  &.h5 {
    font-size: 1.7rem;
  }

  &.h6 {
    font-size: 1.6rem;
  }

  &.p {
    p {
      font-size: fluid-font(1.8rem, 1.6rem);
      line-height: 150%;
      color: var(--clr-primary-600);
    }
  }

  &.caption {
    font-size: 1.2rem;
  }

  &.w-400 {
    font-weight: 400;
  }

  &.w-500 {
    font-weight: 500;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.success {
    color: var(--clr-status-success);
  }

  &.error {
    color: var(--clr-primary-600);
  }

  &.grey {
    color: var(--clr-default-600);
  }

  &.dark {
    color: var(--clr-default-800);
  }

  &.white {
    color: var(--clr-default-100);
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.center {
    text-align: center;
  }

  &.lh-150 {
    line-height: 150%;
  }

  .link {
    color: var(--clr-secondary-900);
    transition: color var(--transition);

    &.focus-visible {
      outline: none;
      color: var(--clr-secondary-400);
    }

    @media (hover) {
      &:hover {
        color: var(--clr-secondary-400);
      }
    }
  }
}
