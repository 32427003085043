.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  width: max-content;
  max-width: 27rem;
  font-family: "Clash Display", sans-serif;
  font-weight: 500;
  text-align: center;

  &--blue {
    border-radius: 0.8rem;
    font-size: 1.6rem;
    line-height: 125%;
    color: var(--clr-default-100);
    background-color: var(--clr-default-200);
    transition: background-color var(--transition);

    @media (hover) {
      &:hover {
        background-color: var(--clr-hover-300);
        transition: background-color var(--transition);
      }
    }
  }

  &--dark {
    border-radius: 0.8rem;
    line-height: 133%;
    color: var(--clr-primary-700);
    background-color: var(--clr-default-950);
    transition: box-shadow var(--transition);

    @media (hover) {
      &:hover {
        box-shadow: var(--clr-shadow-700);
        transition: box-shadow var(--transition);
      }
    }
  }

  &--border-green {
    border: 0.1rem solid var(--clr-default-300);
    border-radius: 0.8rem;
    font-size: 1.6rem;
    line-height: 140%;
    color: var(--clr-default-300);
    transition: box-shadow var(--transition);

    @media (hover) {
      &:hover {
        box-shadow: var(--clr-shadow-800);
        transition: box-shadow var(--transition);
      }
    }
  }

  @media (max-width: em(575)) {
    min-width: 100%;
  }
}
