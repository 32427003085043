.use {
  position: relative;
  z-index: 2;
  margin-top: -5.5rem;
  padding-top: 13rem;
  background-color: var(--clr-primary-100);

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  &__wrapper {
    gap: 5rem;

    @media (max-width: em(575)) {
      gap: 4rem;
    }
  }

  &__title {
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media (max-width: em(992)) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 1.6rem;
    padding: 2.5rem;
    box-shadow: var(--clr-shadow-900);
    background-color: var(--clr-default-100);

    &--1 {
      .use__item-image {
        position: absolute;
        right: 3rem;
        bottom: -12rem;
        width: 38rem;
        height: 35rem;

        @media (max-width: em(1200)) {
          right: -7rem;
        }

        @media (max-width: em(767)) {
          left: 0;
          right: initial;
          width: 30rem;
          height: 30rem;
        }
      }
    }

    &--2 {
      .use__item-image {
        position: absolute;
        right: -6rem;
        bottom: -13rem;
        width: 45.5rem;
        height: 49rem;
        transform: rotate(22deg);

        @media (max-width: em(1200)) {
          right: -19.5rem;
        }

        @media (max-width: em(992)) {
          right: -11rem;
          width: 41rem;
          height: 31rem;
          transform: initial;
        }

        @media (max-width: em(767)) {
          left: 0;
          right: initial;
          bottom: -11rem;
        }
      }
    }

    &--3 {
      .use__item-image {
        position: absolute;
        right: -14.5rem;
        top: 50%;
        width: 47rem;
        height: 34rem;
        transform: translateY(-50%);

        @media (max-width: em(992)) {
          right: -14.5rem;
          top: initial;
          bottom: -6rem;
          width: 35rem;
          height: 25rem;
          transform: initial;
        }
      }
    }

    &--long {
      grid-column: 1 / 3;
      min-height: 26rem;

      @media (max-width: em(992)) {
        min-height: initial;
      }
    }

    &--short {
      flex-direction: column;
      justify-content: space-between;
      grid-column: 3 / 4;
      grid-row: 1 / 3;
      gap: 2rem;
    }

    @media (max-width: em(767)) {
      padding: initial;
      padding-top: 2rem;
      padding-right: 2rem;
      padding-bottom: 20rem;
      padding-left: 2rem;
    }
  }

  &__item-text {
    max-width: 31rem;

    @media (max-width: em(992)) {
      max-width: initial;
      gap: 3rem;
    }

    @media (max-width: em(767)) {
      gap: 2rem;
    }
  }

  &__item-title {
    font-family: 'Clash Display', sans-serif;
    font-weight: 500;
    font-size: fluid-font(3.2rem, 2.4rem);
    line-height: 119%;
    color: var(--clr-default-950);
  }

  &__item-description {
    p {
      font-size: 1.4rem;
      line-height: 140%;
    }
  }

  &__item-image {
  }

  &__item-description-last {

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__item-description--short {
  }
}
