.form__input {
  label {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    span {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 140%;
      color: var(--clr-default-950);
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 0.1rem solid var(--clr-primary-500);
    border-radius: 0.8rem;
    padding: 1.5rem;
    background-color: var(--clr-primary-100);
    transition: border-color var(--transition);

    input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 1.6rem;
      color: var(--clr-primary-400);
      background-color: transparent;

      &::placeholder {
        color: var(--clr-primary-450);
      }

      &.focus-visible {
        outline: none;
      }
    }

    &:focus-within {
      border-color: var(--clr-default-300);
    }
  }
}