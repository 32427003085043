.corporate {
  overflow: hidden;
  padding-top: 13rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 6rem;
  }

  &__wrapper {
    position: relative;
    padding-bottom: 16rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      padding-bottom: initial;
      gap: 3rem;
    }
  }

  &__content-wrapper {
    gap: 2.5rem;
  }

  &__content {
    max-width: 60rem;
    gap: 5rem;

    @media (max-width: em(1080)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      max-width: initial !important;
    }

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &__text {
    gap: 2.5rem;
  }

  &__title {
  }

  &__description {
  }

  &__list {
    gap: 1.5rem;
  }

  &__item {
    position: relative;
    padding-left: 3.5rem;
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 150%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(../img/ui/done-list.svg);
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }

  &__button {
    min-width: 16.5rem;
    min-height: 5rem;
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: -27rem;
    width: 40rem;
    height: 79rem;

    @media (max-width: em(992)) {
     display: none;
    }
  }

  &__image-bg {
    position: absolute;
    right: -113rem;
    bottom: -78rem;
    z-index: -1;
    width: 203rem;
    height: 155rem;

    @media (max-width: em(992)) {
      display: none;
     }
  }
}
