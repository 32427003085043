
.ask {
  padding-top: 13rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 6rem;
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    border-radius: 3rem;
    padding-top: 8rem;
    padding-right: 6rem;
    padding-bottom: 8rem;
    padding-left: 6rem;
    background-color: var(--clr-default-100);
    gap: 3rem;

    @media (max-width: em(1180)) {
      padding-top: 4rem;
      padding-right: 3rem;
      padding-bottom: 4rem;
      padding-left: 3rem;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
      margin-bottom: initial;
      gap: 5rem;
    }

    @media (max-width: em(767)) {
      padding-top: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
    }
  }

  &__content {
    max-width: 39rem;
    gap: 4rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title-wrapper {
    gap: 1.5rem;
  }

  &__contacts {
    gap: 4rem;

    @media (max-width: em(992)) {
      gap: 2rem;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    @media (max-width: em(992))  {
      gap: 1rem;
    }
  }

  &__contact-title {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: transparent;
      stroke: var(--clr-default-300);
    }

    span {
      font-size: 1.4rem;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }

  &__contact-text {
    p, a {
      font-size: 1.8rem;
      line-height: 150%;
      color: var(--clr-primary-400);
    }
  }

  &__form-wrapper {
    min-width: 59rem;

    @media (max-width: em(1180)) {
      min-width: 50rem;
    }

    @media (max-width: em(992)) {
      min-width: initial !important;
    }
  }

  &__form-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem 2rem;

    @media (max-width: em(767)) {
      gap: 2rem;
    }

    @media (max-width: em(575)) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }

  &__form {
    gap: 3rem;

    &-textarea {
      grid-column: 1 / 3;

      @media (max-width: em(575)) {
        grid-column: initial;
      }
    }
  }

  &__form-button {
    min-width: 14rem;
    min-height: 5rem;
    color: var(--clr-primary-700);
  }
}

.iti {
  position: initial;
}


.iti__flag-container {
  border-radius: 0.8rem;
  min-width: 5rem;
  background-color: var(--clr-default-100);
}

.iti__selected-flag {
  justify-content: center;
  padding: initial;
}

.iti__arrow {
  display: none;
}


