.header {
  z-index: 5;
  min-height: 8rem;

  &__wrapper {
    @media (max-width: em(992)) {
      gap: 1rem;
    }
  }

  &__logo-wrapper {
    gap: 3rem;
  }

  &__enter-list {
    position: relative;
    align-items: center;
    padding-left: 3rem;
    gap: 3rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0.1rem;
      height: 1.7rem;
      background-color: var(--clr-default-870);
      transform: translateY(-50%);

      @media (max-width: em(992)) {
        display: none;
      }
    }

    @media (max-width: em(1080)) {
      padding-left: 1rem;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
      padding-left: initial;
    }
  }

  &__enter-item {
    a {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: var(--clr-primary-200);
      gap: 1rem;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -4px;
        width: 0;
        height: 2px;
        background-color: var(--clr-default-300);
        transition: width 0.3s;
      }

      @media (hover) {
        &:hover::before {
          width: 60%;
          transition: width 0.3s;
        }
      }

      svg {
        width: 2.5rem;
        height: 2.5rem;
        fill: var(--clr-default-860);
      }

      @media (max-width: $burgerMQ) {
        font-weight: 600;
        font-size: 2rem;
      }
    }

    &.active {
      a {
        &::before {
          content: "";
          position: absolute;
          right: 0;
          bottom: -4px;
          width: 60%;
          height: 2px;
          background-color: var(--clr-default-300);
          transition: width 0.3s;
        }
      }
    }
  }

  &__menu-wrapper {
    gap: 3rem;

    @media (max-width: em(992)) {
      margin-left: auto;
    }
  }

  &__separator {
    display: block;
    width: 0.1rem;
    height: 1.7rem;
    background-color: var(--clr-default-870);

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__buttons {
    align-items: center;
    gap: 1.5rem;

    @media (max-width: em(575)) {
      flex-direction: column;
      align-items: start;
      gap: 3rem;
    }
  }

  &__our {
    position: relative;
    font-size: 1.5rem;
    line-height: 133%;
    color: var(--clr-primary-200);
    transition: color var(--transition);

    @media (max-width: em(575)) {
      font-weight: 600;
      font-size: 2rem;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -4px;
      width: 0;
      height: 2px;
      background-color: var(--clr-default-300);
      transition: width 0.3s;
    }

    @media (hover) {
      &:hover {
        color: var(--clr-default-100);
        transition: color var(--transition);
        &::before {
          width: 60%;
          transition: width 0.3s;
        }
      }
    }
  }

  &__button-registration {
    border: 0.1rem solid var(--clr-default-870);
    border-radius: 0.8rem;
    min-width: 15rem;
    min-height: 4.5rem;
    font-size: 15px;
    color: var(--clr-default-100);
    transition: border var(--transition), color var(--transition);

    @media (max-width: em(575)) {
      min-width: 100%;
    }

    @media (hover) {
      &:hover {
        border: 0.1rem solid var(--clr-default-300);
        color: var(--clr-default-300);
        transition: border var(--transition), color var(--transition);
      }
    }
  }
}
