$overlayBgColor: rgba(0, 0, 0, 0.77);

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: $overlayBgColor;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);

  &__wrapper {
    @include flex-all-center;

    padding: 2.5rem;
    min-height: 100%;
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 80rem;
    background-color: var(--clr-default-100);
    opacity: 0;
    transform: translateY(-100%);
    transition: transform var(--transition), opacity var(--transition);

    &-account {
      border-radius: 1.5rem;
      padding: 4rem;

      @media (max-width: em(575)) {
        padding-top: 3rem;
        padding-right: 1.5rem;
        padding-bottom: 3rem;
        padding-left: 1.5rem;
      }
    }
  }

  &__close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 2rem;
    height: 2rem;

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 0.2rem;
      background-color: var(--clr-default-900);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);

    .modal__content {
      opacity: 1;
      transform: translateY(0);
      transition: transform var(--transition), opacity var(--transition);
    }
  }
}
