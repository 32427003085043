.online {
  overflow: hidden;
  margin-top: -9rem;
  padding-top: 14rem;
  background-image: var(--clr-default-850);

  @media (max-width: em(992)) {
    padding-top: 12rem;
  }

  &__wrapper {
    position: relative;
  }

  &__info {
    position: relative;
    z-index: 2;
    gap: 1.5rem;
  }

  &__button-card {
    min-height: 4rem;
  }

  &__title {
    text-align: center;
    color: var(--clr-primary-700);
  }

  &__button-account {
    border-radius: 1.2rem;
    min-width: 23rem;
    min-height: 6rem;
    color: var(--clr-default-100);
    gap: 1rem;

    svg {
      width: 2rem;
      height: 2rem;
      fill: transparent;
      stroke: var(--clr-default-100);
    }
  }

  &__cards {
    margin-top: 5rem;

    @media (max-width: em(992)) {
      margin-top: 3rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  &__list {
  }

  &__item {
  
  }

  &__bg {
    position: absolute;
    left: 50%;
    bottom: -60rem;
    z-index: 0;
    width: 126rem;
    height: 126rem;
    transform: translateX(-50%);

    @media (max-width: em(1380)) {
      bottom: -75rem;
    }

    @media (max-width: em(767)) {
      bottom: -85rem;
    }
  }
}
