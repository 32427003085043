.hero {
  position: relative;
  overflow: hidden;
  margin-top: -9rem;
  padding-top: 16rem;
  background-image: var(--clr-default-850);

  &::before {
    content: '';
    position: absolute;
    right: -21rem;
    top: -27rem;
    z-index: 0;
    border-radius: 50%;
    width: 66rem;
    height: 66rem;
    background-color: var(--clr-radial-400);
    filter: blur(13rem);
  }

  @media (max-width: em(992)) {
    padding-top: 14rem;
  }

  @media (max-width: em(575)) {
    padding-top: 12rem;
  }

  &__wrapper {
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      padding-bottom: 8rem;
      gap: 2rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__content {
    z-index: 1;
    flex-shrink: 0;
    max-width: 69rem;
    gap: 10rem;

    @media (max-width: em(1280)) {
      flex-shrink: initial;
    }

    @media (max-width: em(992)) {
      max-width: initial;
      gap: 5rem;
    }
  }

  &__info {
    gap: 5rem;
  }

  &__title-wrapper {
    gap: 2.5rem;
  }

  &__title {
    color: var(--clr-default-100);
  }

  &__description {
    max-width: 49rem;

    p {
      font-size: fluid-font(1.8rem, 1.6rem);
      line-height: 150%;
      color: var(--clr-primary-200);
    }

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__buttons {
    align-items: center;
    gap: 3rem;

    @media (max-width: em(575)) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__button-open {
    border-radius: 1.2rem;
    min-width: 23rem;
    min-height: 6rem;
    color: var(--clr-default-100);
    gap: 1rem;

    svg {
      width: 2rem;
      height: 2rem;
      fill: transparent;
      stroke: var(--clr-default-100);
    }
  }

  &__devivered {
    border-radius: 1.2rem;
    padding: 1.5rem;
    width: max-content;
    min-height: 9rem;
    background-color: var(--clr-primary-300);
    gap: 1.2rem;

    &-value {
      font-family: 'ClashDisplay', sans-serif;
      font-weight: 400;
      font-size: fluid-font(4.2rem, 3.2rem);
      color: var(--clr-default-100);
    }

    &-text {
      max-width: 7rem;
      font-size: 1.4rem;
      line-height: 140%;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &__images {
    position: relative;
    min-width: 65rem;

    @media (max-width: em(1280)) {
      min-width: initial;
    }
  }

  &__image-phone {
    position: absolute;
    right: 7rem;
    bottom: -62rem;
    z-index: 1;
    width: 65rem;
    height: 122rem;

    @media (max-width: em(1280)) {
      position: relative;
      right: initial;
      bottom: initial;
      width: initial;
      height: initial;
      max-width: 40rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
      max-width: 30rem;
    }
  }

  &__image-bg {
    position: absolute;
    left: 50%;
    bottom: -60rem;
    z-index: 0;
    width: 122rem;
    height: 122rem;
    transform: translateX(-55%);

    @media (max-width: em(1280)) {
      bottom: -34rem;
      width: 80rem;
      height: 100rem;
      transform: translateX(-45%);
    }

    @media (max-width: em(992)) {
      bottom: -10rem;
      width: 100%;
      height: 60rem;
      transform: translateX(-50%);
    }

    @media (max-width: em(575)) {
      width: 60rem;
      height: 60rem;
    }
  }
}

