.stack {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.column-reverse {
    flex-direction: column-reverse;
  }

  &.row {
    flex-direction: row;
  }

  &.row-reverse {
    flex-direction: row-reverse;
  }

  &.justify-flex-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.circle-center {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
