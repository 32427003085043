.payment {
  padding-top: 19rem;

  @media (max-width: em(1280)) {
    padding-top: 12rem;
  }

  &__wrapper {
    gap: 3rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }

    @media (max-width: em(575)) {
      gap: 2rem;
    }
  }

  &__info {
    max-width: 59rem;
    gap: 2.5rem;

    @media (max-width: em(1280)) {
      max-width: initial;
    }

    @media (max-width: em(575)) {
      gap: 2rem;
    }
  }

  &__title {
    span {
      color: var(--clr-primary-800);
    }
  }

  &__text {
    gap: 2rem;

    @media (max-width: em(575)) {
      gap: 1.5rem;
    }
  }

  &__image-wrapper {
    position: relative;
    border-radius: 2.5rem;
    min-width: 59rem;
    min-height: 37.5rem;
    box-shadow: var(--clr-shadow-900);
    background-color: var(--clr-default-100);

    @media (max-width: em(1280)) {
      padding-top: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
      min-width: 43rem;
    }

    @media (max-width: em(992)) {
      min-height: initial;
    }

    @media (max-width: em(575)) {
      min-width: 100%;
    }
  }

  &__image {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 53.5rem;
    height: 44rem;
    transform: translateX(-50%);

    @media (max-width: em(1280)) {
     position: relative;
     left: initial;
     bottom: -2rem;
     width: initial;
     height: initial;
     transform: initial;
    }

    @media (max-width: em(992)) {
      bottom: 0;
    }
  }
}

