.benefits {
  padding-top: 13rem;
  padding-bottom: 13rem;

  @media (max-width: em(992)) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @media (max-width: em(767)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &__wrapper {
    gap: 5rem;

    @media (max-width: em(767)) {
      gap: 3rem;
    }
  }

  &__info {
    gap: 3rem;

    @media (max-width: em(1080)) {
      flex-direction: column;
    }
  }

  &__text {
    max-width: 79.5rem;
    gap: 4rem;

    @media (max-width: em(1080)) {
      max-width: initial;
    }

    @media (max-width: em(575)) {
      gap: 2rem;
    }
  }

  &__button {
    height: max-content;
    min-height: 5rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    gap: 2rem;
  }

  &__item {
    border-radius: 1.6rem;
    padding-top: 2.5rem;
    padding-right: 2rem;
    padding-bottom: 2.5rem;
    padding-left: 2rem;
    background-color: var(--clr-default-100);
    gap: 1.5rem;

    @media (max-width: em(575)) {
      padding-top: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  &__item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    min-height: 12rem;
    background-color: var(--clr-primary-100);
    aspect-ratio: 16/4;
  }

  &__item-title {
    font-weight: 500;
    font-size: fluid-font(2.2rem, 1.8rem);
    line-height: 127%;
    color: var(--clr-default-950);
  }

  &__item-text {
  }
}
